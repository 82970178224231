require('./bootstrap');

import Alpine from 'alpinejs';
import intlTelInput from 'intl-tel-input';
import GSTC from "gantt-schedule-timeline-calendar";

window.Alpine = Alpine;

Alpine.start();

window.intlTelInput = intlTelInput;
window.TextSignature = require('text-signature');

window.GSTC = GSTC;

$('#page.dashboard #main #cabecera #buscador input').on('keypress', function (event) {
    if (event.which === 13) {
        window.location = '/busqueda-interna?s=' + $(this).val();
    }
});

$('header #logo h1').on('click', function (event) {
    $('header #menu').toggleClass('show');
});

$(function () {
    if ($('#page.dashboard #menu').length > 0) {
        $('#page.dashboard #menu').prepend('<span id="mnu_mobile"></span>');
        $('#page.dashboard #menu #mnu_mobile').on('click', function (event) {
            $('#page.dashboard #menu').toggleClass('show');
        });
    }
    if ($('#page.layout_wide #main #cabecera').length > 0) {
        $('#page.layout_wide #main #cabecera').prepend('<span id="mnu_mobile"></span>');
        $('#page.layout_wide #main #cabecera #mnu_mobile').on('click', function (event) {
            $('#page.layout_wide #main #cabecera #mainmenu').toggleClass('show');
        });
    }
});
